export const PRICE_FROM_LIST = 'list';
export const PRICE_FROM_SALES_TERRITORY = 'salesTerritory';
export const PRICE_FROM_CUSTOMER_PRICE_MANUFACTURER =
  'customerPriceByManufacturer';
export const PRICE_FROM_CUSTOMER_PRICE_PART_CATEGORY =
  'customerPriceByPartCategory';
export const PRICE_FROM_CUSTOMER_PRICE_PRODUCT_LINE =
  'customerPriceByProductLine';
export const PRICE_FROM_CUSTOMER_PRICE_PART = 'customerPriceByPart';

export const PRICE_FROM_VALUES = [
  PRICE_FROM_LIST,
  PRICE_FROM_SALES_TERRITORY,
  PRICE_FROM_CUSTOMER_PRICE_MANUFACTURER,
  PRICE_FROM_CUSTOMER_PRICE_PART_CATEGORY,
  PRICE_FROM_CUSTOMER_PRICE_PRODUCT_LINE,
  PRICE_FROM_CUSTOMER_PRICE_PART,
] as const;

export const PRICE_TYPE_LIST_PRICE_DISCOUNT = 'listPriceDiscount';
export const PRICE_TYPE_PROFIT_MARGIN = 'profitMargin';
export const PRICE_TYPE_FIXED = 'fixed';

export const PRICE_TYPE_VALUES = [
  PRICE_TYPE_LIST_PRICE_DISCOUNT,
  PRICE_TYPE_PROFIT_MARGIN,
  PRICE_TYPE_FIXED,
] as const;

// prettier-ignore
export type PriceFromType =
  typeof PRICE_FROM_VALUES[number];

// prettier-ignore
export type PriceTypeType =
  typeof PRICE_TYPE_VALUES[number];
