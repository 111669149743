import {
  PRICE_FROM_SALES_TERRITORY,
  PRICE_TYPE_LIST_PRICE_DISCOUNT,
  PRICE_TYPE_FIXED,
} from '@/shared/sales-pilot/PriceConstants';
import { GetPricingBreakdownByManufacturerResponse } from '@/shared/types/controllers/spa/SPACustomerPriceControllerTypes';
import { isNotEmpty } from '@/shared/types/util/TypeGuards';

export const getReadableDiscountType = (
  pricingBreakdownByManufacturer:
    | GetPricingBreakdownByManufacturerResponse
    | undefined,
) => {
  const { profitMarginDiscount, listPriceDiscount } =
    pricingBreakdownByManufacturer ?? {};

  if (isNotEmpty(profitMarginDiscount)) {
    return 'Customer cost up profit margin';
  }

  if (isNotEmpty(listPriceDiscount)) {
    if (listPriceDiscount.discountType === PRICE_TYPE_FIXED) {
      return 'Customer SPA';
    }
    // this is PRICE_TYPE_DISCOUNT but specific
    if (listPriceDiscount.discountFrom === PRICE_FROM_SALES_TERRITORY) {
      return 'Sales Territory Discount';
    }
    if (listPriceDiscount.discountType === PRICE_TYPE_LIST_PRICE_DISCOUNT) {
      return 'Off list';
    }
  }

  return '';
};
