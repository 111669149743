import { ReactNode, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents } from '@qb/frontend/utils/formatCurrency';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { isNotEmpty } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { getReadableDiscountType } from '@/src/utils/getReadableDiscountType';
export type PartToAdd = {
  id: number;
  partNumber: string;
  description: string;
  manufacturer: {
    name: string;
  };
  images: Array<{
    target: string;
  }>;
};
const INITIAL_QUANTITY = 1;
type PartToAddCardContentProps = {
  part: PartToAdd;
  onAddToQuote: (part: PartToAdd, qty: number) => void;
  customerID?: number;
};
export const PartToAddCardContent = ({
  part,
  onAddToQuote,
  customerID
}: PartToAddCardContentProps) => {
  const tenantAlias = useTenantAlias();
  const [quantity, setQuantity] = useState(INITIAL_QUANTITY);
  const {
    data: partPublicData,
    isLoading
  } = useQuery(spaQueries.part.publicData(part.id, tenantAlias));
  const listPrice = isEmpty(partPublicData?.publicQtyAndPrice?.originalPrice) ? partPublicData?.publicQtyAndPrice?.price : partPublicData?.publicQtyAndPrice?.originalPrice;
  const manufacturerID = partPublicData?.part.manufacturer.id;
  const partPricingRequest = useQuery({
    ...spaQueries.customerPrice.pricingBreakdownByManufacturer(tenantAlias, {
      manufacturerID: Number(manufacturerID),
      customerID: Number(customerID),
      partID: part.id
    }),
    enabled: isNotEmpty(customerID) && isNotEmpty(manufacturerID)
  });
  const discountFrom = getReadableDiscountType(partPricingRequest.data);
  return <>
      <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto minmax(90px, auto)',
      rowGap: 2
    }} data-sentry-element="Box" data-sentry-source-file="PartToAddCardContent.tsx">
        <Box mr={4} data-sentry-element="Box" data-sentry-source-file="PartToAddCardContent.tsx">
          <NumberInput initialValue={INITIAL_QUANTITY} onValueChange={setQuantity} data-sentry-element="NumberInput" data-sentry-source-file="PartToAddCardContent.tsx" />
        </Box>
        <Button variant="contained" color="secondary" fullWidth onClick={() => onAddToQuote(part, quantity)} data-sentry-element="Button" data-sentry-source-file="PartToAddCardContent.tsx">
          Add to quote
        </Button>
        <Box p={2} bgcolor="common.white" data-sentry-element="Box" data-sentry-source-file="PartToAddCardContent.tsx">
          <ImageWithFallback tenantAlias={tenantAlias} src={part.images[0]?.target} alt={part.partNumber} width={70} height={70} data-sentry-element="ImageWithFallback" data-sentry-source-file="PartToAddCardContent.tsx" />
        </Box>
        <Box bgcolor="common.white" py={2} data-sentry-element="Box" data-sentry-source-file="PartToAddCardContent.tsx">
          <Typography variant="h6" mb={1} data-sentry-element="Typography" data-sentry-source-file="PartToAddCardContent.tsx">
            {part.partNumber}
          </Typography>
          <Typography variant="bodyMedium" sx={clampLinesSx(2)} data-sentry-element="Typography" data-sentry-source-file="PartToAddCardContent.tsx">
            {part.description}
          </Typography>
        </Box>
      </Box>
      <Stack data-sentry-element="Stack" data-sentry-source-file="PartToAddCardContent.tsx">
        <Row index={0} label="Manufacturer" value={<Typography variant="bodyMediumPrimary" color="text.primary">
              {part.manufacturer.name}
            </Typography>} data-sentry-element="Row" data-sentry-source-file="PartToAddCardContent.tsx" />

        {isLoading ? <>
            <Row index={1} label="On-hand qty" value={<Skeleton width={120} />} />
            <Row index={2} label="Product lifecycle" value={<Skeleton width={120} />} />

            <Row index={3} label="List Price" value={<Skeleton width={120} />} />
          </> : <>
            <Row index={1} label="On-hand qty" value={isNotEmpty(partPublicData?.publicQtyAndPrice?.onHandQty) ? <Typography variant="bodyMedium">
                    {partPublicData.publicQtyAndPrice.onHandQty}
                  </Typography> : '-'} />
            <Row index={2} label="Product lifecycle" value={isNotEmpty(partPublicData?.part.active) ? <Typography variant="bodyMediumPrimary" color={partPublicData.part.active ? 'success.main' : 'error.main'}>
                    {partPublicData.part.active ? 'Active' : 'Not Active'}
                  </Typography> : '-'} />
            <Row index={3} label="List Price" value={isNotEmpty(listPrice) ? <Typography variant="h6">{formatCents(listPrice)}</Typography> : '-'} />
            <Row index={4} label="Customer Discounts" sublabel={discountFrom} value={isNotEmpty(partPricingRequest.data?.lowestPrice) ? <Typography variant="h6">
                    {formatCents(partPricingRequest.data?.lowestPrice)}
                  </Typography> : '-'} />
          </>}
      </Stack>
    </>;
};
type RowProps = {
  index: number;
  label: string;
  sublabel?: string;
  value: ReactNode;
};
const getRowBackgroundColor = (index: number) => {
  return index % 2 === 0 ? 'common.backgroundColor' : 'common.white';
};
const Row = ({
  index,
  label,
  sublabel,
  value
}: RowProps) => {
  return <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
    backgroundColor: getRowBackgroundColor(index),
    p: 2
  }} data-sentry-element="Stack" data-sentry-component="Row" data-sentry-source-file="PartToAddCardContent.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="PartToAddCardContent.tsx">
        <Typography variant="bodyMedium" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="PartToAddCardContent.tsx">
          {label}
        </Typography>
        {sublabel && <Typography variant="bodySmall" mt={0.5}>
            {sublabel}
          </Typography>}
      </Box>
      {value}
    </Stack>;
};